import styled from '@emotion/styled';
import MuiSvgIcon from '../MuiIcon';

const Path = styled.path`
  fill-rule: evenodd;
  clip-rule: evenodd;
`;

const WhitePath = styled(Path)`
  fill: white;
`;

const DarkMode = (props) => {
  return (
    <MuiSvgIcon {...props}>
      <rect width="24" height="24" rx="12" />
      <WhitePath d="M7.19263 12.96V16.0992C7.19263 16.8584 7.57263 17.5664 8.20383 17.9864L9.48943 18.8416C11.0102 19.8528 12.9894 19.8528 14.5094 18.8416L15.795 17.9864C16.427 17.5664 16.8062 16.8576 16.8062 16.0992V12.96" />
      <WhitePath d="M10.1623 6.47604L5.22314 9.22004C4.18474 9.79684 4.18474 11.2896 5.22314 11.8664L10.1623 14.6104C11.3047 15.2448 12.6943 15.2448 13.8375 14.6104L18.7767 11.8664C19.8151 11.2896 19.8151 9.79684 18.7767 9.22004L13.8375 6.47604C12.6943 5.84164 11.3055 5.84164 10.1623 6.47604Z" />
      <Path d="M9.87109 5.95131C11.1954 5.21642 12.8043 5.21642 14.1286 5.95131L19.068 8.69543C19.068 8.69544 19.068 8.69543 19.068 8.69543C20.5179 9.50083 20.5179 11.5854 19.068 12.3908C19.068 12.3908 19.068 12.3908 19.068 12.3908L14.1288 15.1348C12.8046 15.8697 11.1946 15.8699 9.87093 15.1349L4.93167 12.3909C4.93166 12.3909 4.93168 12.3909 4.93167 12.3909C3.48178 11.5855 3.48175 9.50085 4.93164 8.69545C4.93165 8.69545 4.93162 8.69546 4.93164 8.69545L9.87109 5.95131ZM10.4535 7.00047L5.51441 9.74444C4.88754 10.0926 4.8875 10.9936 5.51437 11.3418L10.4535 14.0858C11.4146 14.6195 12.5839 14.6197 13.546 14.0859L18.4852 11.3419C19.1121 10.9936 19.1121 10.0927 18.4853 9.74446L13.5463 7.00058C12.5843 6.46685 11.4155 6.46675 10.4535 7.00047Z" />
    </MuiSvgIcon>
  );
};

export default DarkMode;

import MuiSvgIcon from '../MuiIcon';

const DarkMode = (props) => {
  return (
    <MuiSvgIcon {...props}>
      <rect width="24" height="24" rx="12" />
      <path
        d="M5.56194 13.8498C4.64202 11.0953 5.68417 8.01629 8.11818 6.29737C10.5522 4.57844 13.7916 4.63379 16.064 6.43312C18.3363 8.23246 19.0942 11.3422 17.9228 14.061C16.7514 16.7797 13.9331 18.4523 11.0099 18.1636"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse cx="16" cy="12.5" rx="2" ry="2.5" fill="white" />
      <ellipse
        rx="2"
        ry="2.5"
        transform="matrix(-1 0 0 1 7 12.5)"
        fill="white"
      />
    </MuiSvgIcon>
  );
};

export default DarkMode;

import styled from '@emotion/styled';
import MuiSvgIcon from '../MuiIcon';

const Path = styled.path`
  fill: white;
`;

const DarkMode = (props) => {
  return (
    <MuiSvgIcon {...props}>
      <rect width="24" height="24" rx="12" />
      <Path d="M5.08137 14.9366C4.93318 14.546 5.47265 14.2855 5.80097 14.5437C6.70913 15.2579 7.85461 15.6839 9.0996 15.6839C12.049 15.6839 14.44 13.2929 14.44 10.3435C14.44 9.09851 14.014 7.95303 13.2998 7.04487C13.0416 6.71654 13.3022 6.17707 13.6927 6.32526C16.2106 7.28075 18.0003 9.71571 18.0003 12.5687C18.0003 16.2555 15.0116 19.2442 11.3248 19.2442C8.47181 19.2442 6.03685 17.4545 5.08137 14.9366Z" />
      <Path d="M10.1282 8.74353C10.2614 8.40588 10.7393 8.40588 10.8724 8.74353L11.1437 9.43133C11.1844 9.53442 11.266 9.61602 11.3691 9.65668L12.0568 9.92794C12.3945 10.0611 12.3945 10.539 12.0568 10.6722L11.3691 10.9434C11.266 10.9841 11.1844 11.0657 11.1437 11.1688L10.8724 11.8566C10.7393 12.1942 10.2614 12.1942 10.1282 11.8566L9.85697 11.1688C9.81631 11.0657 9.73471 10.9841 9.63162 10.9434L8.94382 10.6722C8.60616 10.539 8.60616 10.0611 8.94382 9.92794L9.63162 9.65668C9.73471 9.61602 9.81631 9.53442 9.85697 9.43133L10.1282 8.74353Z" />
      <Path d="M9.00034 12.8C9.00034 13.3523 8.55262 13.8 8.00034 13.8C7.44805 13.8 7.00034 13.3523 7.00034 12.8C7.00034 12.2478 7.44805 11.8 8.00034 11.8C8.55262 11.8 9.00034 12.2478 9.00034 12.8Z" />
    </MuiSvgIcon>
  );
};

export default DarkMode;

import styled from '@emotion/styled';
import MuiSvgIcon from '../MuiIcon';

const Path = styled.path`
  fill: white;
`;

const DarkMode = (props) => {
  return (
    <MuiSvgIcon {...props}>
      <rect width="24" height="24" rx="12" />
      <Path d="M7 12.7341C7 12.6236 7.08954 12.5341 7.2 12.5341H11.3C11.4105 12.5341 11.5 12.6236 11.5 12.7341V17.7507C11.5 17.8612 11.4105 17.9507 11.3 17.9507H8C7.44772 17.9507 7 17.503 7 16.9507V12.7341Z" />
      <Path d="M17 12.7341C17 12.6236 16.9105 12.5341 16.8 12.5341H12.7C12.5895 12.5341 12.5 12.6236 12.5 12.7341V17.7507C12.5 17.8612 12.5895 17.9507 12.7 17.9507H16C16.5523 17.9507 17 17.503 17 16.9507V12.7341Z" />
      <Path d="M7 11.1341C7 11.355 7.17909 11.5341 7.4 11.5341H16.6C16.8209 11.5341 17 11.355 17 11.1341V9.28408C17 8.7318 16.5523 8.28408 16 8.28408H8C7.44772 8.28408 7 8.7318 7 9.28408V11.1341Z" />
      <Path d="M11.137 5.78338C11.9538 6.1972 13.2578 7.67778 12.8334 8.47414C12.4089 9.2705 10.4168 9.08107 9.6 8.66725C8.78322 8.25342 8.46517 7.27237 8.8896 6.47601C9.31404 5.67966 10.3202 5.36955 11.137 5.78338Z" />
      <Path d="M13.4806 6.79638C12.9278 7.09527 12.0608 8.13534 12.3673 8.67438C12.6739 9.21341 14.0379 9.04728 14.5908 8.74839C15.1436 8.44949 15.3433 7.77022 15.0367 7.23119C14.7302 6.69216 14.0335 6.49749 13.4806 6.79638Z" />
    </MuiSvgIcon>
  );
};

export default DarkMode;

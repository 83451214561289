/* eslint-disable prefer-template */

export default function updateQuery(uri, key, value) {
  if (uri) {
    const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) return uri.replace(re, '$1' + key + '=' + value + '$2');
    return uri + separator + key + '=' + value;
  }
  return '';
}

import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import updaetQuery from '@utils/updaetQuery';
import { Spacings } from '@styles/index';

const StyledAvatar = styled.img`
  border-radius: ${({ $circular }) =>
    $circular ? '50%' : Spacings.SPACING_2B};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  object-fit: cover;
  cursor: pointer;
  user-drag: none;
  user-select: none;
`;

const Avatar = (props) => {
  const { src, className, circular, size, alt, ...rest } = props;
  const webp = updaetQuery(src, 'fm', 'webp');
  const imageType = src?.includes('png') ? 'png' : 'jpg';
  const isBase64 = src?.startsWith('data:image');
  return (
    <picture>
      {!isBase64 && ( // Don't optimize image since the image is local, and won't load otherwise.
        <>
          <source srcSet={webp} type="image/webp" />
          <source srcSet={src} type={`image/${imageType}`} />
        </>
      )}
      <StyledAvatar
        {...rest}
        $circular={circular}
        visibleByDefault
        src={src}
        className={className}
        height={size}
        width={size}
        decoding="async"
        loading="eager"
        alt={alt}
      />
    </picture>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  circular: PropTypes.bool,
  alt: PropTypes.string
};

Avatar.defaultProps = {
  src: '/static/images/filler.png',
  height: 'initial',
  width: 'initial',
  size: null,
  className: '',
  circular: false,
  alt: 'Avatar'
};

export default Avatar;

import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import P1 from '@base/P1/P1';
import { Spacings, Fonts } from '@styles/index';

const MenuItem = styled.div`
  width: 100%;
  cursor: pointer;
  display: grid;
  align-items: center;
  grid-template: 1fr / 20px 1fr ${({ $hasRightIcon }) =>
      $hasRightIcon ? 'max-content' : ''};
  grid-column-gap: ${Spacings.SPACING_2B};
  padding: ${Spacings.SPACING_14} ${Spacings.SPACING_4B};
  background-color: transparent;
  transition: background-color 300ms ease-in-out;
  &:hover {
    background-color: var(--color-divider);
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled(P1)`
  line-height: 129%;
  color: var(--color-text-primary);
  font-weight: ${Fonts.SEMIBOLD};
`;

const ProfileItem = ({ label, value, onClick, rightIcon, leftIcon }) => {
  return (
    <MenuItem $hasRightIcon={!!rightIcon} onClick={onClick} key={value}>
      <IconContainer>{leftIcon}</IconContainer>
      <Label>{label}</Label>
      {rightIcon}
    </MenuItem>
  );
};

ProfileItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  rightIcon: PropTypes.node,
  leftIcon: PropTypes.node
};

ProfileItem.defaultProps = {
  onClick: undefined,
  rightIcon: null,
  leftIcon: null
};

export default ProfileItem;

import useFetch from '@hooks/useFetch';

import { getIsLoggedIn, getMeUid } from '@api/selectors/auth';
import useMyInfo from './useMyInfo';

/**
 * @returns { Object } An object with the response and function to refetch access control data
 */
const useIsCampusAmbassador = () => {
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const meUid = useMyInfo(getMeUid);

  const { data, mutate } = useFetch(
    isLoggedIn ? `v1/user/me/campus_ambassador/?uid=${meUid}` : null
  );
  return {
    isCampusAmbassador: !!data?.isCampusAmbassador,
    mutate
  };
};

export default useIsCampusAmbassador;

import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Link from '@base/Link/Link';
import { socialIconsLinks } from '@layout/Footer/constants';
import { Spacings, Fonts } from '@styles/index';

const FooterContainer = styled.div`
  width: calc(100% - ${Spacings.SPACING_5B});
  margin: auto;
  margin-top: ${Spacings.SPACING_1B};
  padding-top: ${Spacings.SPACING_4B};
  padding-bottom: 10px;
  border-top: 1px solid var(--color-divider);
`;

const FooterLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const FooterText = styled.p`
  font-size: ${Fonts.EXTRA_SMALL};
  line-height: ${Fonts.PARA_LINE_HEIGHT};
  color: var(--color-text-secondary);
`;

const FooterLabel = styled(FooterText)`
  font-weight: ${Fonts.SEMIBOLD};
  &:hover {
    color: var(--color-text-primary);
  }
`;

const SocialIconsContainer = styled.div`
  margin-top: ${Spacings.SPACING_3B};
  display: flex;
`;

const SocialImage = styled.div`
  margin-right: ${Spacings.SPACING_2B};
  width: 20px;
  height: 20px;
  border-radius: 5px;
  transition: background-color 200ms ease;
  background-color: var(--color-divider);
  &:hover {
    background-color: var(--color-text-secondary);
    svg > path {
      fill: var(--color-base-1);
    }
  }
`;

const CopyRightText = styled(FooterText)`
  margin-top: ${Spacings.SPACING_3B};
  cursor: default;
`;

const Separator = styled.div`
  margin: 0 ${Spacings.SPACING_1B};
  color: var(--color-text-secondary);
`;

const footerLinks = [
  { label: 'Terms & Conditions', href: '/terms' },
  {
    label: 'Privacy Policy',
    href: '/privacy',
    useSeparator: true
  },
  { label: 'About', href: '/about' },
  {
    label: 'Careers',
    href: '/careers',
    useSeparator: true
  },
  { label: 'Blog', href: 'https://blog.unacademy.com', useSeparator: true }
];

const FooterLink = ({ href, label, useSeparator }) => {
  return (
    <>
      {useSeparator && <Separator>•</Separator>}
      <Link href={href} newTab>
        <FooterLabel>{label}</FooterLabel>
      </Link>
    </>
  );
};

FooterLink.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  useSeparator: PropTypes.bool.isRequired
};

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLinksContainer>
        {footerLinks.slice(0, 2).map(({ href, label, useSeparator }) => (
          <FooterLink
            href={href}
            label={label}
            useSeparator={useSeparator}
            key={label}
          />
        ))}
      </FooterLinksContainer>
      <FooterLinksContainer>
        {footerLinks.slice(2).map(({ href, label, useSeparator }) => (
          <FooterLink
            href={href}
            label={label}
            useSeparator={useSeparator}
            key={label}
          />
        ))}
      </FooterLinksContainer>
      <SocialIconsContainer>
        {socialIconsLinks.map(({ icon, link, name }) => {
          const Icon = icon;
          return (
            <a href={link} key={name} rel="noopener noreferrer" target="_blank">
              <SocialImage>
                <Icon color="var(--color-text-secondary)" size="20px" />
              </SocialImage>
            </a>
          );
        })}
      </SocialIconsContainer>
      <CopyRightText>
        &copy; {new Date().getFullYear()} Sorting Hat Technologies Pvt Ltd
      </CopyRightText>
    </FooterContainer>
  );
};

export default Footer;

import styled from '@emotion/styled';
import MuiSvgIcon from '../MuiIcon';

const Path = styled.path`
  fill: white;
`;

const DarkMode = (props) => {
  return (
    <MuiSvgIcon {...props}>
      <rect width="24" height="24" rx="12" />
      <Path d="M18 13.1999C18 16.5136 15.3137 19.1999 12 19.1999C8.68629 19.1999 6 16.5136 6 13.1999V12.7044C6 12.4258 6.22587 12.1999 6.5045 12.1999H17.4955C17.7741 12.1999 18 12.4258 18 12.7044V13.1999Z" />
      <Path d="M15 8.3999C15 10.0568 13.6569 11.3999 12 11.3999C10.3431 11.3999 9 10.0568 9 8.3999C9 6.74305 10.3431 5.3999 12 5.3999C13.6569 5.3999 15 6.74305 15 8.3999Z" />
    </MuiSvgIcon>
  );
};

export default DarkMode;

import styled from '@emotion/styled';
import MuiSvgIcon from '../MuiIcon';

const Path = styled.path`
  fill-rule: evenodd;
  clip-rule: evenodd;
`;

const DarkMode = (props) => {
  return (
    <MuiSvgIcon {...props}>
      <rect width="24" height="24" rx="12" />
      <Path
        d="M18.3002 15.0286V9.37123C18.3002 8.87073 18.0335 8.40873 17.6002 8.15883L12.7002 5.33013C12.2669 5.08023 11.7335 5.08023 11.3002 5.33013L6.4002 8.15813C5.9669 8.40873 5.7002 8.87073 5.7002 9.37123V15.0293C5.7002 15.5298 5.9669 15.9918 6.4002 16.2417L11.3002 19.0704C11.7335 19.3203 12.2669 19.3203 12.7002 19.0704L17.6002 16.2417C18.0335 15.9911 18.3002 15.5291 18.3002 15.0286Z"
        fill="white"
      />
      <Path d="M11.0378 4.87532C11.6334 4.5318 12.3667 4.5318 12.9623 4.87532L17.8623 7.70402C18.4584 8.04776 18.825 8.68324 18.825 9.3712V15.0286C18.825 15.7167 18.4583 16.3518 17.8629 16.6962L12.9625 19.5251C12.3669 19.8686 11.6334 19.8687 11.0378 19.5252L6.13776 16.6965C6.1377 16.6964 6.13782 16.6965 6.13776 16.6965C5.54185 16.3527 5.17505 15.7172 5.17505 15.0293V9.3712C5.17505 8.68313 5.5418 8.04799 6.13721 7.70364L11.0378 4.87532ZM11.5623 5.78488C11.5623 5.78491 11.5624 5.78486 11.5623 5.78488L6.66289 8.61257C6.66287 8.61258 6.66291 8.61256 6.66289 8.61257C6.39173 8.76942 6.22505 9.0583 6.22505 9.3712V15.0293C6.22505 15.3423 6.39176 15.6309 6.66234 15.7869L11.5623 18.6156C11.5624 18.6157 11.5623 18.6156 11.5623 18.6156C11.8332 18.7718 12.1667 18.7719 12.4376 18.6157C12.4376 18.6157 12.4375 18.6158 12.4376 18.6157L17.3372 15.7872C17.3372 15.7872 17.3372 15.7872 17.3372 15.7872C17.6084 15.6304 17.775 15.3415 17.775 15.0286V9.3712C17.775 9.05816 17.6083 8.76964 17.3378 8.61359L12.4378 5.78488C12.4377 5.78485 12.4378 5.78492 12.4378 5.78488C12.1669 5.62874 11.8332 5.62872 11.5623 5.78488Z" />
      <Path d="M12.0001 11.675C12.29 11.675 12.5251 11.9101 12.5251 12.2V19.256C12.5251 19.546 12.29 19.781 12.0001 19.781C11.7101 19.781 11.4751 19.546 11.4751 19.256V12.2C11.4751 11.9101 11.7101 11.675 12.0001 11.675Z" />
      <Path d="M18.5659 8.40945C18.7108 8.66056 18.6248 8.98164 18.3737 9.12661L12.2627 12.6546C12.0116 12.7996 11.6905 12.7135 11.5455 12.4624C11.4005 12.2113 11.4866 11.8902 11.7377 11.7453L17.8487 8.21727C18.0998 8.0723 18.4209 8.15834 18.5659 8.40945Z" />
      <Path d="M5.43443 8.40945C5.5794 8.15834 5.90048 8.0723 6.15159 8.21727L12.2626 11.7453C12.5137 11.8902 12.5997 12.2113 12.4548 12.4624C12.3098 12.7135 11.9887 12.7996 11.7376 12.6546L5.62661 9.12661C5.3755 8.98164 5.28946 8.66056 5.43443 8.40945Z" />
    </MuiSvgIcon>
  );
};

export default DarkMode;

import styled from '@emotion/styled';
import { useState } from 'react';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import DarkModeIcon from '@icon/ProfileDropdown/DarkMode';
import useDarkModeStatus from '@stores/useDarkModeStatus';
import {
  getUserPreferredThemeSetter,
  getIsDarkModeOn
} from '@stores/selectors/darkModeStatusSelectors';
import ProfileItem from './ProfileItem';
import profileOptionsMap from './profileOptionsMap';

const AntSwitch = styled(Switch)`
  &.MuiSwitch-root {
    width: 24px;
    height: 16px;
    padding: 0;
    display: flex;
    .MuiSwitch-switchBase {
      padding: 3px;
      color: ${({ $isDarkModeOn }) =>
        $isDarkModeOn
          ? 'var(--color-base-fill)'
          : 'var(--color-text-secondary)'};
      &:hover {
        background-color: transparent;
      }
      &.Mui-checked {
        transform: translateX(8px);
      }
      .MuiSwitch-thumb {
        width: 10px;
        height: 10px;
        box-shadow: none;
      }
    }
    .MuiSwitch-track {
      border: 2px solid
        ${({ $isDarkModeOn }) =>
          $isDarkModeOn
            ? 'var(--color-base-fill);'
            : 'var(--color-text-secondary);'};
      border-radius: 8px;
      opacity: 1;
      background-color: var(--color-base-1);
    }
  }
`;

const DarkModeOption = ({ sendSegmentEvent }) => {
  const isDarkModeOn = useDarkModeStatus(getIsDarkModeOn);
  const setUserPreferredTheme = useDarkModeStatus(getUserPreferredThemeSetter);
  const [isChecked, setIsChecked] = useState(isDarkModeOn);
  const handleChange = () => {
    const shouldActivateDarkMode = !isChecked;
    setIsChecked(shouldActivateDarkMode);
    setUserPreferredTheme(shouldActivateDarkMode);
    if (shouldActivateDarkMode)
      sendSegmentEvent('Settings - Dark Mode Selected');
  };
  return (
    <ProfileItem
      label="Dark mode"
      value={profileOptionsMap.DARK_MODE}
      onClick={handleChange}
      rightIcon={
        <AntSwitch
          checked={isChecked}
          $isDarkModeOn={isDarkModeOn}
          value="checkedA"
        />
      }
      leftIcon={
        <DarkModeIcon color="var(--color-text-secondary)" size="20px" />
      }
    />
  );
};

DarkModeOption.propTypes = {
  sendSegmentEvent: PropTypes.func.isRequired
};

export default DarkModeOption;

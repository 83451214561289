import MuiSvgIcon from '../MuiIcon';

const DarkMode = (props) => {
  return (
    <MuiSvgIcon {...props}>
      <rect width="24" height="24" rx="12" />
      <path
        d="M17.8471 10.3404C18.051 11.4365 18.051 12.5615 17.8471 13.6577C17.7727 14.0582 17.4317 14.3771 16.9896 14.4574C15.3257 14.7596 13.6221 14.8271 11.9394 14.6592C11.9168 15.1535 11.8878 15.6455 11.8525 16.1339C11.7821 17.107 11.2328 17.7448 10.7128 17.41L10.441 17.235C8.95308 16.2771 7.55867 14.8965 6.30991 13.145C5.8967 12.5654 5.8967 11.4346 6.30991 10.855C7.55867 9.10347 8.95308 7.72291 10.441 6.76499L10.7128 6.59C11.2328 6.2552 11.7821 6.89299 11.8525 7.86605C11.8878 8.35382 11.9167 8.84518 11.9394 9.33884C13.622 9.17099 15.3257 9.23846 16.9896 9.54068C17.4317 9.62097 17.7727 9.93989 17.8471 10.3404Z"
        fill="white"
      />
    </MuiSvgIcon>
  );
};

export default DarkMode;

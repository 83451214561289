import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const MuiSvgIcon = styled(SvgIcon)`
  width: ${({ $size }) => $size};
  height: ${({ $size }) => $size};
`;

const MuiIcon = ({ size, color, children, ...rest }) => {
  return (
    <MuiSvgIcon {...rest} htmlColor={color} $size={size}>
      {children}
    </MuiSvgIcon>
  );
};

MuiIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};

MuiIcon.defaultProps = {
  size: '24px',
  color: 'var(--color-base-fill)'
};

export default MuiIcon;

const profileOptionsMap = {
  PROFILE: 'profile',
  SETTINGS: 'settings',
  MODERATOR_DASHBOARD: 'mod',
  EDUCATOR_DASHBOARD: 'dashboard',
  MY_DASHBOARD: 'mydashboard',
  REFER_YOUR_FRIENDS: 'refer',
  DARK_MODE: 'darkMode',
  SIGN_OUT: 'signOut',
  HELP_AND_SUPPORT: 'helpAndSupport',
  REPORT_AN_ISSUE: 'reportAnIssue'
};

export default profileOptionsMap;

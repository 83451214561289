import styled from '@emotion/styled';
import MuiSvgIcon from '../MuiIcon';

const Path = styled.path`
  fill-rule: evenodd;
  clip-rule: evenodd;
`;

const DarkMode = (props) => {
  return (
    <MuiSvgIcon {...props}>
      <rect width="24" height="24" rx="12" />
      <Path
        d="M16.9002 9.058H12.3705C12.1388 9.058 11.9218 8.9432 11.7916 8.7514L10.8081 7.3059C10.6779 7.1148 10.4616 7 10.2299 7H7.1002C6.3267 7 5.7002 7.6265 5.7002 8.4V16.8C5.7002 17.5735 6.3267 18.2 7.1002 18.2H16.9002C17.6737 18.2 18.3002 17.5735 18.3002 16.8V10.458C18.3002 9.6852 17.6737 9.058 16.9002 9.058Z"
        fill="white"
      />
      <Path d="M5.17505 11.9C5.17505 11.6101 5.4101 11.375 5.70005 11.375H18.3C18.59 11.375 18.825 11.6101 18.825 11.9C18.825 12.1899 18.59 12.425 18.3 12.425H5.70005C5.4101 12.425 5.17505 12.1899 5.17505 11.9Z" />
    </MuiSvgIcon>
  );
};

export default DarkMode;

import MuiSvgIcon from '../MuiIcon';

const DarkMode = (props) => {
  return (
    <MuiSvgIcon {...props}>
      <circle cx="12" cy="12.4" r="12" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3724 5.95201C13.2599 5.64486 12.9629 5.43994 12.6301 5.43994H11.368C11.0378 5.44291 10.7444 5.64725 10.6328 5.95201L10.232 7.04655C10.1685 7.21845 10.0453 7.36299 9.88417 7.45497L8.69462 8.12794C8.53319 8.21927 8.344 8.2516 8.16058 8.21921L6.99311 8.01268C6.66554 7.95458 6.33603 8.10438 6.16958 8.38707L5.53851 9.45453C5.37151 9.73675 5.40307 10.0914 5.6174 10.341L6.37546 11.229C6.4961 11.3686 6.56234 11.5457 6.56241 11.7287V13.0746C6.56234 13.2576 6.4961 13.4347 6.37546 13.5743L5.60951 14.4623C5.39518 14.7119 5.36362 15.0666 5.53062 15.3488L6.16169 16.4162C6.32825 16.6992 6.65818 16.8491 6.98601 16.7906L8.15348 16.5841C8.3369 16.5517 8.52609 16.584 8.68752 16.6754L9.87707 17.3483C10.0382 17.4403 10.1614 17.5849 10.2249 17.7568L10.6257 18.8513C10.7382 19.1584 11.0351 19.3634 11.368 19.3634H12.6301C12.9632 19.3636 13.2606 19.1587 13.3732 18.8513L13.7739 17.7568C13.8375 17.5846 13.961 17.44 14.1226 17.3483L15.3113 16.6754C15.4727 16.584 15.6619 16.5517 15.8454 16.5841L17.0128 16.7906C17.3404 16.8487 17.6699 16.6989 17.8364 16.4162L18.4674 15.3488C18.6344 15.0666 18.6029 14.7119 18.3885 14.4623L17.6226 13.5743C17.502 13.4347 17.4357 13.2576 17.4356 13.0746V11.7287C17.4357 11.5457 17.502 11.3686 17.6226 11.229L18.3885 10.341C18.6029 10.0914 18.6344 9.73675 18.4674 9.45453L17.8364 8.38707C17.6698 8.10407 17.3399 7.95423 17.012 8.01268L15.8446 8.21921C15.6611 8.2516 15.472 8.21927 15.3105 8.12794L14.1218 7.45497C13.9602 7.36329 13.8367 7.21867 13.7731 7.04655L13.3724 5.95201ZM8.90493 12.4017C8.90493 10.6928 10.2902 9.30756 11.999 9.30756C13.7078 9.30756 15.0931 10.6928 15.0931 12.4017C15.0931 14.1105 13.7078 15.4957 11.999 15.4957C10.2902 15.4957 8.90493 14.1105 8.90493 12.4017Z"
        fill="white"
      />
      <circle cx="11.999" cy="12.4017" r="1.93381" fill="white" />
    </MuiSvgIcon>
  );
};

export default DarkMode;
